<template>
  <div class="detail">
    <el-container v-bind:style="{ height: Height + 'px' }">
      <el-main
        style="background: white; margin: 20px"
      >
        <div class="main_box">
          <div class="bread" v-if="articleDetail._source">
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              v-if="articleDetail._source"
            >
              <!-- <el-breadcrumb-item :to="{ path: '/academic' }"
                ><span v-if="$route.query.webPage == '1'">中文期刊推荐</span>
                <span v-if="$route.query.webPage == '2'">英文期刊推荐</span>
                <span v-if="$route.query.webPage == '4'">机构订阅</span>
                <span v-if="$route.query.webPage == '3'">个人中心</span></el-breadcrumb-item
              > -->
              <el-breadcrumb-item
                v-if="$route.query.webPage == '1'"
                :to="{
                  path: '/academic',
                  query: {
                    webPage: $route.query.webPage,
                  },
                }"
              >
                <span>中文期刊推荐</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item
                v-if="$route.query.webPage == '2'"
                :to="{
                  path: '/academic',
                  query: {
                    webPage: $route.query.webPage,
                  },
                }"
              >
                <span>英文期刊推荐</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item
                v-if="$route.query.webPage == '3'"
                :to="{
                  path: '/academic',
                  query: {
                    webPage: $route.query.webPage,
                  },
                }"
              >
                <span>个人中心</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item
                v-if="$route.query.webPage == '4'"
                :to="{
                  path: '/academic',
                  query: {
                    webPage: $route.query.webPage,
                  },
                }"
              >
                <span>机构订阅</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item
                v-if="$route.query.journal && $route.query.webPage !== '4'"
                :to="{
                  path: '/journalsList',
                  query: {
                    subject: $route.query.type,
                    language: articleDetail._source.language,
                    webPage: $route.query.webPage
                  },
                }"
                >{{ $route.query.type }}期刊</el-breadcrumb-item
              >
              <el-breadcrumb-item
                v-if="$route.query.journal && $route.query.webPage == '4'"
                :to="{
                  path: '/journalsList',
                  query: {
                    subject: $route.query.type,
                    language: 2,
                    webPage: $route.query.webPage
                  },
                }"
                >{{ $route.query.type }}期刊</el-breadcrumb-item
              >
              <el-breadcrumb-item
                v-if="$route.query.journal"
                :to="{
                  path: '/journalsDetail',
                  query: {
                    id: $route.query.journal_id,
                    subject: $route.query.type,
                    webPage: $route.query.webPage
                  },
                }"
                >{{ $route.query.journal }}</el-breadcrumb-item
              >
              <el-breadcrumb-item
                :to="{
                  path: '/academic',
                  query: {
                    search: $route.query.search,
                    type: $route.query.type,
                    webPage: $route.query.webPage
                  },
                }"
                v-if="$route.query.search"
                >期刊检索：“{{ $route.query.search }}”</el-breadcrumb-item
              >
              <el-breadcrumb-item v-if="$route.query.title">{{
                $route.query.title
              }}</el-breadcrumb-item>
              <el-breadcrumb-item v-if="$route.query.period">{{
                $route.query.period
              }}</el-breadcrumb-item>
              <!-- <el-breadcrumb-item
                v-if="articleDetail._source.language == '0'"
                >{{ articleDetail._source.ch_title }}</el-breadcrumb-item
              >
              <el-breadcrumb-item
                v-if="articleDetail._source.language == '1'"
                >{{ articleDetail._source.title }}</el-breadcrumb-item
              > -->
            </el-breadcrumb>
            <!-- <router-link :to="{ path: '/academic' }"
              ><el-button type="primary" size="mini" plain
                >返回</el-button
              ></router-link
            > -->
          </div>
          <div class="articleList" v-if="articleDetail._source">
            <div class="article_list">
              <div class="title">
                <div class="titleTop">
                  <h2 v-if="articleDetail._source.language == '0'">
                    {{ articleDetail._source.ch_title }}
                  </h2>
                  <h2 v-if="articleDetail._source.language == '1'">
                    {{ articleDetail._source.title }}
                  </h2>
                  <h3
                    v-if="
                      articleDetail._source.language == '1' &&
                      articleDetail._source.ch_title !== ''
                    "
                    style="margin: 0; text-align: center"
                  >
                    {{ articleDetail._source.ch_title }}
                  </h3>
                  <!-- <h3 class="author_css">
                    {{ authors }}
                  </h3> -->
                </div>
                <div class="but">
                  <!-- <el-button
                    size="mini"
                    style="margin: 0 20px 0 0"
                    round
                    @click="fullText(articleDetail)"
                    v-if="
                      $route.query.ispdf == 0 &&
                      !articleDetail.typeFull &&
                      articleDetail._source.pdf_link == '' &&
                      articleDetail._source.personalized !== 1
                    "
                    >获取全文</el-button
                  > -->
                  <el-button
                    size="mini"
                    style="margin: 0 20px 0 0"
                    round
                    @click="orinagal01(articleDetail)"
                    v-if="
                      $route.query.ispdf == 0 &&
                      !articleDetail.typeFull &&
                      articleDetail._source.pdf_link == '' &&
                      articleDetail._source.personalized == 1
                    "
                    >原文传递</el-button
                  >
                  <!-- <el-button
                    
                    size="mini"
                    style="background: #b14a75; color: white"
                    >已传递</el-button
                  > -->
                  <el-button
                    size="mini"
                    style="margin: 0 20px 0 0;background: #b14a75; color: white"
                    round
                    v-if="$route.query.ispdf == 1"
                    >已传递</el-button
                  >
                  <!-- -->
                  <el-button
                    size="mini"
                    style="margin: 0 20px 0 0"
                    round
                    @click="down_link(articleDetail)"
                    v-if="
                      $route.query.ispdf == 0 &&
                      !articleDetail.typeFull &&
                      articleDetail._source.pdf_link !== ''
                    "
                    >下载链接</el-button
                  >
                  <el-button
                    size="mini"
                    style="margin: 0 20px 0 0"
                    round
                    type="success"
                    v-if="
                      articleDetail.typeFull &&
                      articleDetail.typeFull == '需求提交成功'
                    "
                    >已提交</el-button
                  >
                  <el-button
                    size="mini"
                    style="margin: 0 20px 0 0"
                    round
                    type="danger"
                    v-if="
                      articleDetail.typeFull &&
                      articleDetail.typeFull !== '需求提交成功'
                    "
                    >{{ articleDetail.typeFull }}</el-button
                  >
                  <img
                    v-if="articleDetail._source.isCollect == 0"
                    @click="collection(articleDetail)"
                    src="../../../assets/imgs/icons/collect.png"
                    alt=""
                  />
                  <el-popconfirm
                    title="确认取消收藏吗？"
                    @confirm="collection(articleDetail)"
                  >
                    <img
                      v-if="articleDetail._source.isCollect == 1"
                      slot="reference"
                      src="../../../assets/imgs/icons/collect1.png"
                      alt=""
                    />
                  </el-popconfirm>
                </div>
              </div>
              <div class="article_essay">
                <!-- <p>
                  <img src="../../../assets/imgs/icons/photo.png" alt="" /><span
                    v-for="(item_author, index) in articleDetail._source.author"
                    :key="index"
                    >{{ item_author }} ；</span    authors
                  >
                </p> -->
                <p v-if="authors !== ''">
                  <!-- <img src="../../../assets/imgs/icons/doi.pnauthors" alt="" /> -->
                  <span class="tit_article">作者：</span>
                  <span>
                    {{ authors }}
                  </span>
                </p>

                <p
                  v-if="
                    articleDetail._source.abstract.length > 0 &&
                    articleDetail._source.language == '1'
                  "
                >
                  <!-- <img
                    src="../../../assets/imgs/icons/文本摘要.png"
                    alt=""
                  /> -->
                  <span class="tit_article">摘要：</span>

                  <span style="text-align: justify">{{
                    articleDetail._source.abstract
                  }}</span>
                </p>
                <p
                  v-if="
                    articleDetail._source.ch_abstract.length > 0 &&
                    articleDetail._source.language == '0'
                  "
                >
                  <!-- <img
                    src="../../../assets/imgs/icons/文本摘要.png"
                    alt=""
                  /> -->
                  <span class="tit_article" style="text-align: justify"
                    >摘要：</span
                  >

                  <span>{{ articleDetail._source.ch_abstract }}</span>
                </p>
                <p
                  v-if="
                    articleDetail._source.ch_abstract.length > 0 &&
                    articleDetail._source.language == '1'
                  "
                >
                  <!-- <img
                    v-if="articleDetail._source.abstract.length > 0"
                    src="../../../assets/imgs/icons/翻译.png"
                    alt=""
                  /> -->
                  <span class="tit_article">翻译：</span>

                  <span style="text-align: justify">{{
                    articleDetail._source.ch_abstract
                  }}</span>
                </p>
                <p
                  v-if="
                    articleDetail._source.keyword.length > 0 &&
                    articleDetail._source.language == '1'
                  "
                >
                  <!-- <img
                    src="../../../assets/imgs/icons/journalsci.png"
                    alt=""
                  /> -->
                  <span class="tit_article">关键词：</span>
                  <span>{{ en_words }}</span>
                  <!-- <span
                    v-for="(item_keyword, index) in articleDetail._source
                      .keyword"
                    :key="index"
                    >{{ item_keyword }} ；</span
                  > -->
                </p>
                <p
                  v-if="
                    articleDetail._source.ch_keyword.length > 0 &&
                    articleDetail._source.language == '0'
                  "
                >
                  <!-- <img
                    src="../../../assets/imgs/icons/journalsci.png"
                    alt=""
                  /> -->
                  <span class="tit_article"> 关键词：</span>
                  <span>{{ ch_words }}</span>
                  <!-- <span
                    v-for="(item_keyword, index) in articleDetail._source
                      .ch_keyword"
                    :key="index"
                    >{{ item_keyword }} ；</span
                  > -->
                </p>
                <p v-if="articleDetail._source.doi !== ''">
                  <!-- <img src="../../../assets/imgs/icons/doi.png" alt="" /> -->
                  <span class="tit_article">DOI：</span>
                  <span>
                    {{ articleDetail._source.doi }}
                  </span>
                </p>
              </div>

              <!-- <p v-if="articleDetail._source.ch_abstract.length > 0">
                <span
                  v-if="articleDetail._source.ch_abstract.length > 100 && !more"
                  >{{ articleDetail._source.ch_abstract.slice(0, 100) }}
                  <span class="more_text" @click="moreText">...更多</span></span
                >
              </p>
              <p v-if="articleDetail._source.ch_abstract.length > 0">
                <span v-if="more"
                  >{{ articleDetail._source.ch_abstract }}
                  <span class="more_text" @click="moreText"> 收起</span></span
                >
              </p> -->
              <!-- <el-divider></el-divider> -->
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog title="原文传递" :visible.sync="dialogVisible" :width="'500px'">
      <el-form :model="form" ref="form">
        <el-form-item
          label="邮箱确认"
          :label-width="formLabelWidth"
          prop="email"
          :rules="[
            { required: true, message: '请输入邮箱地址', trigger: 'blur' },
            {
              type: 'email',
              message: '请输入正确的邮箱地址',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="orinagal('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import {
  article_details,
  cancel_collect,
  collect,
  update_article,
  emailpass,
  downloadpdf,
} from "@/Api/journals";
import { partChBook } from "@/Api/manage";
export default {
  provide() {
    return {
      reloading: this.reloading,
      imgs: require("../../../assets/imgs/icons/杂志期刊.svg"),
      // Height: 0,
    };
  },
  data() {
    return {
      Height: 0,
      keys: "",
      isRouterAlive: true,
      essayData: {},
      tags: [],
      issue: [],
      options: [],
      dimensionArr: [], // 默认选中的部分
      article: [],
      more: false,
      totalCount: 0,
      journalArticle: {},
      dialogVisible: false,
      articleDetail: "",
      webpage: window.sessionStorage.getItem("webPage"),
      article_id: "",
      total: 0,
      one: "",
      pages: 1,
      currentPage1: 1,
      introduceAll: false,
      essayId: "",
      partch: {},
      authors: "",
      en_words: "",
      ch_words: "",
      form: {
        email: "",
      },
      formLabelWidth: "120px",
      orinagal_data: "",
      subscribeOr: localStorage.getItem("subscribeOr"),
    };
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 80; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 80;
    };
  },
  created() {
    this.essayId = this.$route.query.id;
    // console.log(this.essayId);
    this.detail();
    if (this.$route.query.webPage) {
      this.keys = this.$route.query.webPage.toString();
    //   this.activeIndex = this.$route.query.webPage.toString();
    }
    // this.unreads();
    // if (window.sessionStorage.getItem("webPage")) {
    //   this.keys = window.sessionStorage.getItem("webPage");
    //   // console.log(this.keys)
    // } else {
    //   this.keys = "1";
    //   window.sessionStorage.setItem("webPage", this.keys);
    // }
    this.update();
  },
  methods: {
    reloading() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    handleSelect(key) {
      // console.log( key);
      this.keys = key;
    //   window.sessionStorage.setItem("webPage", this.keys);
      let path = this.$route.path;
      //   console.log(path);
      if (path !== "/academic") {
        this.$router.push({
          name: "Academic",
          query: { webPage: key },
        });
      }
      // if (this.keys == 1) {
      //   this.reloads();
      // }
      // if (this.keys == 2) {
      //   this.reloading();
      // }
    },
    update() {
      let user_id = {
        user_openid: localStorage.getItem("code"),
      };
      update_article(user_id)
        .then((res) => {
          //   console.log("id", res);
          this.total = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 期刊文章详情
    detail() {
      let articleId = {
        article_id: this.$route.query.id,
        // article_id: "02154ba886e32bec79298f1b691aa35f",
        user_openid: localStorage.getItem("code"),
        journal_id: this.$route.query.journal_id,
        // user_openid:'bfb6cccff1014a4aaadc1403479ba050'
      };
      article_details(articleId)
        .then((res) => {
          //   console.log(res);
          this.articleDetail = res.data.data.hits[0];
          //   console.log(this.articleDetail);
          this.authors = "";
          this.articleDetail._source.author.forEach((item) => {
            this.authors = this.authors + item + " ；";
          });
          this.ch_words = "";
          this.articleDetail._source.ch_keyword.forEach((item) => {
            this.ch_words = this.ch_words + item + " ；";
          });
          this.en_words = "";
          this.articleDetail._source.keyword.forEach((item) => {
            this.en_words = this.en_words + item + " ；";
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 文章收藏
    collection(state) {
      let id = {
        user_openid: localStorage.getItem("code"),
        article_id: state._id,
      };
      if (state._source.isCollect == 0) {
        collect(id)
          .then((res) => {
            // console.log(res);
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.detail(this.article_id);
            // console.log(this.articleDetail)
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (state._source.isCollect == 1) {
        cancel_collect(id)
          .then((res) => {
            // console.log(res);
            this.$message({
              message: "收藏已取消",
              //   type: "success",
            });
            this.detail(this.article_id);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      //   this.getList();
    },
    // 翻页
    handleCurrentChange(val) {
      this.pages = val;
      this.currentPage1 = val;
      this.articleData();
      //   window.scrollTo(0, 0);
    },
    moreText(index) {
      this.more = !this.more;
      if (this.more) {
        this.one = index;
      } else {
        this.one = "";
      }
    },
    moreText01() {
      this.introduceAll = !this.introduceAll;
    },
    fullText(data) {
      if (data._source.doi && data._source.doi !== "") {
        this.partch = {
          demand: [
            {
              id: 1,
              type_code: "Doi",
              text: data._source.doi,
              original_text: "<b>" + data._source.doi + "</b>",
              priority: "一般",
              remark: "",
              supplement:
                "<CitationTracker><UserCode>" +
                localStorage.getItem("code") +
                "</UserCode><ArticleId>" +
                data._id +
                "</ArticleId><Doi>" +
                data._source.doi +
                "</Doi><Link>" +
                data._source.detail_link +
                "</Link><Handle>" +
                "doi" +
                "</Handle></CitationTracker>",
            },
          ],
        };
      } else if (data._source.detail_link && data._source.detail_link !== "") {
        this.partch = {
          demand: [
            {
              id: 1,
              type_code: "Url",
              text: data._source.detail_link,
              original_text: "<b>" + data._source.detail_link + "</b>",
              priority: "一般",
              remark: "",
              supplement:
                "<CitationTracker><UserCode>" +
                localStorage.getItem("code") +
                "</UserCode><ArticleId>" +
                data._id +
                "</ArticleId><Doi>" +
                data._source.doi +
                "</Doi><Link>" +
                data._source.detail_link +
                "</Link><Handle>" +
                "link" +
                "</Handle></CitationTracker>",
            },
          ],
        };
      } else {
      }
      if (this.articleDetail._source.isCollect == 0) {
        this.collection(this.articleDetail);
      }

      partChBook(this.partch)
        .then((res) => {
          //   console.log(res);
          if (res.data.error_code == 0) {
            if (res.data.data[1].msg == "需求提交成功") {
              this.$message({
                message: res.data.data[1].msg,
                type: "success",
              });
              this.orinagal00(data);
              //   this.collection(data);
            } else {
              this.$message({
                message: res.data.data[1].msg,
                type: "warning",
              });
            }

            Vue.set(this.articleDetail, "typeFull", res.data.data[1].msg);
            // console.log(this.chBook);
            // this.dialogVisible = false;
            // this.demandList();
          }
          // if (res.data.error_code == 1) {
          //   this.$message({
          //     message: res.data.msg,
          //     type: "warning",
          //   });
          // }
        })
        .catch((err) => {
          // console.log(err);
          // this.$message({
          //   message: "提交失败",
          //   type: "warning",
          // });
        });
    },
    orinagal01(data) {
      this.orinagal_data = data;
      if(localStorage.getItem("email").indexOf('code') !==-1 ){
        this.form.email = '';
      }else{
        this.form.email = localStorage.getItem("email");
      }
      
      this.dialogVisible = true;
    },
    orinagal(formName) {
      //   console.log(date);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          let date = this.orinagal_data;
          let pas = {
            email: this.form.email,
            article_id: date._id,
            pdf: date._source.pdf,
            title: date._source.title,
          };
          if (date._source.language == "0") {
            pas.title = date._source.ch_title;
          }
          emailpass(pas)
            .then((res) => {
              console.log(res);
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.message,
                  type: "success",
                });
                this.dialogVisible = false;
              } else {
                this.$message({
                  message: res.data.message,
                  type: "warning",
                });
              }
            })
            .catch((err) => {
              console.log(err);
              //   this.$message({
              //     message: err.data.message,
              //     type: "warning",
              //   });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      return;
    },
    orinagal00(date) {
      //   console.log(date);

      // alert('submit!');

      let pas = {
        email: "",
        article_id: date._id,
        pdf: date._source.pdf,
        title: date._source.title,
      };
      if (date._source.language == "0") {
        pas.title = date._source.ch_title;
      }
      emailpass(pas)
        .then((res) => {
          //   console.log(res);
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.dialogVisible = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    down_link(down) {
      window.open(down._source.pdf_link, "_blank");
      let down_id = {
        article_id: down._id,
      };
      downloadpdf(down_id)
        .then((res) => {
          //   console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style   lang="less" scoped>
.detail {
  min-width: 1360px;

  .el-aside {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 200px;

    // display: inline-block;
  }

  .el-main {
    // height: 100%;
    background-color: #e9eef3;
    color: #333;
    // text-align: center;
    // line-height: 160px;
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
  .el-menu {
    background-color: #fff;
  }
}
.is-active div {
  background-color: #B14A75;
  span {
    color: #fff;
  }
  i {
    color: #fff;
  }
}
.el-menu-item {
  padding: 5px 50px !important;
  background-color: #fff;
  margin-bottom: 5px;
}
.aside_item {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  span {
    color: #b1aec8;
    font-size: 15px;
  }
}
.item {
  line-height: 30px;
  /deep/ .el-badge__content.is-fixed {
    position: absolute;
    top: 5px;
    right: 13px;
    transform: translateY(-50%) translateX(100%);
  }
}
.main_box {
  //   background: white;
  min-width: 1200px;
  max-width: 1550px;
  margin: auto;
  min-height: 700px;
  padding: 0px 60px 0 60px;
  .bread {
    // line-height: 50px;

    display: flex;
    justify-content: space-between;
    .el-breadcrumb {
      line-height: 28px;
    }
  }
  .box {
    min-width: 1200px;
    max-width: 1550px;
    margin: auto;
    .box_top {
      margin: 20px 0;
      display: flex;
      padding: 0 10%;
      .journal_img {
        margin-right: 20px;
        img {
          width: 175px;
          height: 205px;
          border-radius: 5px;
        }
      }
      .journal_data {
        width: 80%;
        h2:hover {
          color: #a0a0a0;
        }
        .title {
          margin-left: 30px;
        }
        .tags {
          display: flex;
          margin: 10px 0 10px 30px;
          .el-tag {
            margin-right: 10px;
          }
        }
        p {
          line-height: 30px;
          span {
            display: inline-block;
            width: 50%;
            span {
              display: inline-block;
              //   text-align: right;
              width: 100px;
            }
          }
        }
      }
    }
    .box_issue {
      //   margin: 20px 0;
      display: flex;
      padding: 20px 10%;
      .issue {
        // width: 360px;
        margin-right: 50px;
        .el-cascader-panel {
          min-width: 300px;
          max-width: 450px;
          /deep/ .el-cascader-menu {
            min-width: 150px;
          }
        }
      }
    }
  }
}
.article_essay {
  margin: 0 10%;
  p {
    .tit_article {
      width: 80px;
      font-size: 16px;
      font-weight: 700;
      text-align: right !important;
    }
    span {
      max-width: 89%;
    }
  }
}
.articleList {
  .article_list {
    //   margin: 20px 0;
    margin-bottom: 10px;
    padding: 20px 30px;
    border-radius: 5px;
    //   background: #eeeeee;
    .title {
      width: 100%;
      // min-width: 300px;
      //   display: flex;

      //   justify-content: space-between;
      .author_css {
        margin: 5px 25%;
        text-align: center;
        line-height: 20px;
      }
      h2 {
        width: 100%;
        line-height: 32px;
        text-align: center;
      }
      p {
      }
      img {
        width: 30px;
        cursor: pointer;
      }
      .but {
        display: flex;
        justify-content: flex-end;
      }
    }
    h2 {
      font-size: 18px;
      font-weight: 500;
    }
    h3 {
      font-size: 14px;
      font-weight: 100;
    }
    p {
      line-height: 30px;
      margin: 10px;
      display: flex;
      img {
        width: 30px;
        height: 30px;
        // position: relative;
        // top: 10px;
        margin-right: 10px;
      }
      span {
        display: inline-block;
        line-height: 30px;
        //   text-indent:2em;
      }
    }
    .abstract {
      text-indent: 2em;
      overflow: hidden;
      -webkit-line-clamp: 2.5;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
  .page-bar {
    margin: 10px 0;
    display: flex;
    justify-content: center;
  }
}
/deep/ .el-radio {
  display: table;
  width: 80%;
  height: 100%;
  position: absolute;
  color: red;
  .el-radio__input {
    visibility: hidden;
  }
}
/deep/ .el-scrollbar::-webkit-scrollbar {
  display: none;
}
/deep/ .el-cascader-menu__wrap {
  height: 500px;
}
.title {
  //   display: flex;
  //   justify-content: space-between;
}
.details {
  .title {
    // display: flex;
    // justify-content: space-between;
    img {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }
  h2 {
  }
  p {
    line-height: 30px;
    margin: 10px;
    img {
      width: 30px;
      position: relative;
      top: 10px;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      line-height: 30px;
      //   text-indent:2em;
    }
  }
}
.tit {
  width: 100px !important;
  text-align: right;
}
.more_text {
  font-weight: 700;
}
.more_text:hover {
  color: #9be0fc;
  cursor: pointer;
}
</style>